import Window from "pages/Mac/Window";
import React, { useMemo } from "react";

export default function Chrome({ data }) {
  const memoized = useMemo(() => {
    return (
      <iframe
        title="dino"
        src="https://chromedino.com/"
        frameborder="0"
        // scrolling="no"
        width="100%"
        height="100%"
        loading="lazy"
      />
    );
  }, []);
  return (
    <Window {...data} filename="">
      {() => {
        return <div className="component-chrome max-height">{memoized}</div>;
      }}
    </Window>
  );
}
