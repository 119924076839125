import IMAGES from "constants/images";
const PROJECTS = [
  {
    name: "Mint.club",
    url: "https://mint.club",
    image: IMAGES.mint,
  },
  {
    name: "Dixel.club",
    url: "https://dixel.club",
    image: IMAGES.dixel,
  },
  {
    name: "Nomadtask",
    url: "https://nomadtask.com",
    image: IMAGES.nomadtask,
  },
  {
    name: "Gudoks",
    url: "https://gudoks.com",
    image: IMAGES.gudoks,
  },
  {
    name: "Coronatasks",
    url: "https://coronatasks.com",
    image: IMAGES.coronatasks,
  },
  {
    name: "NLM",
    url: "https://neverlose.money",
    image: IMAGES.warren,
  },
  {
    name: "HUNT",
    url: "https://token.hunt.town",
    image: IMAGES.hunt,
  },
];

export default PROJECTS;
