import { ACTION_OPEN_WINDOW, WINDOW_TEXTEDIT } from "atoms/app/state";
import GITHUB from "constants/github";
import IMAGES from "constants/images";
import PRODUCTHUNT from "constants/producthunt";
import PROJECTS from "constants/projects";

const FILE_STRUCTURE = {
  name: "Sung Woo",
  path: "/",
  items: [
    {
      path: "projects",
      name: "Projects",
      folderImg: IMAGES.programmingFolder,
      items: PROJECTS,
    },
    // {
    //   path: "design",
    //   name: "Design",
    //   folderImg: IMAGES.designFolder,
    //   items: [
    //     {
    //       name: "Dixel.club",
    //       url: "https://dixel.club",
    //       image: IMAGES.dixel,
    //     },
    //   ],
    // },
    // {
    //   path: "hackathons",
    //   name: "Hackathons",
    //   folderImg: IMAGES.htmlFolder,
    //   items: [],
    // },
    {
      path: "github",
      name: "Github",
      folderImg: IMAGES.githubFolder,
      items: GITHUB,
    },
    {
      path: "producthunt",
      name: "ProductHunt",
      items: PRODUCTHUNT,
    },
    // {
    //   path: "awards",
    //   name: "Awards",
    //   items: [],
    // },
    {
      app: "TextEdit",
      name: "README.md",
      action: {
        type: ACTION_OPEN_WINDOW,
        payload: WINDOW_TEXTEDIT,
      },
      image: IMAGES.textedit,
    },
  ],
};

export default FILE_STRUCTURE;
