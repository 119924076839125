import { useSpring } from "@react-spring/core";
import { a as three } from "@react-spring/three";
import { a as web } from "@react-spring/web";
import {
  ContactShadows,
  Environment,
  OrbitControls,
  Stats,
  useHelper,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useRef, useState } from "react";
import { PointLightHelper } from "three";
import Macbook from "threejs/Macbook";

function Lights({ props }) {
  const l1ref = useRef();
  const l2ref = useRef();
  //   useHelper(l1ref, PointLightHelper, 0.5, "pink");
  //   useHelper(l2ref, PointLightHelper, 0.5, "pink");

  return (
    <>
      <three.pointLight
        ref={l1ref}
        position={[10, 10, 10]}
        intensity={2.5}
        color={props.open.to([0, 1], ["#f0f0f0", "#d25578"])}
      />

      <three.pointLight
        ref={l2ref}
        position={[10, 40, -70]}
        intensity={2.5}
        color={"#fff"}
      />
    </>
  );
}

export default function Scene() {
  // This flag controls open state, alternates between true & false
  const [open, setOpen] = useState(false);
  // We turn this into a spring animation that interpolates between 0 and 1
  const props = useSpring({ open: Number(open) });

  return (
    <Suspense fallback={null}>
      <web.main
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          background: props.open.to([0, 1], ["#f0f0f0", "#fc6f6f"]),
        }}
      >
        <web.h1
          className="font-40"
          style={{
            opacity: props.open.to([0, 1], [1, 0]),
            transform: props.open.to(
              (o) => `translate3d(-50%,${o * 50 - 150}px,0)`
            ),
          }}
        >
          안녕하세요
        </web.h1>
        <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 0], fov: 35 }}>
          <Lights props={props} />
          <Suspense fallback={null}>
            <group
              rotation={[0, Math.PI, 0]}
              onClick={(e) => (e.stopPropagation(), setOpen(!open))}
            >
              <Macbook
                open={open}
                hinge={props.open.to([0, 1], [1.575, -0.425])}
              />
            </group>
            {/* <Environment preset="city" /> */}
          </Suspense>
          <ContactShadows
            rotation-x={Math.PI / 2}
            position={[0, -4.5, 0]}
            opacity={0.4}
            width={20}
            height={20}
            blur={2}
            far={4.5}
          />
        </Canvas>
        {/* <Stats /> */}
      </web.main>
    </Suspense>
  );
}
