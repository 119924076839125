import dixel from "assets/producthunt/ph-dixel.gif";
import mint from "assets/producthunt/ph-mint.png";
import nlm from "assets/producthunt/ph-nlm.gif";
import flixgang from "assets/producthunt/ph-flixgang.gif";
import testfly from "assets/producthunt/ph-testfly.png";
import lolhunt from "assets/producthunt/ph-lolhunt.gif";
import mac from "assets/producthunt/ph-mac.gif";
import IMAGES from "constants/images";

const PRODUCTHUNT = [
  {
    name: "Dixel",
    url: "https://www.producthunt.com/posts/dixel",
    image: dixel,
  },
  {
    name: "Mint.club",
    url: "https://www.producthunt.com/posts/mint-club",
    image: mint,
  },
  {
    name: "NLM",
    url: "https://www.producthunt.com/posts/neverlose-money",
    image: nlm,
  },
  {
    name: "Flixgang",
    url: "https://www.producthunt.com/posts/flixgang",
    image: flixgang,
  },
  {
    name: "CoronaTasks",
    url: "https://www.producthunt.com/posts/coronatasks",
    image: IMAGES.coronatasks,
  },
  {
    name: "Testfly",
    url: "https://www.producthunt.com/posts/testfly",
    image: testfly,
  },
  {
    name: "LOL Hunt",
    url: "https://www.producthunt.com/posts/lol-hunt",
    image: lolhunt,
  },
  {
    name: "Big Mac Index",
    url: "https://www.producthunt.com/posts/social-media-big-mac-index",
    image: mac,
  },
];

export default PRODUCTHUNT;
