import defaultFolder from "assets/icons/default-folder.png";
import useOutsideClick from "hooks/useOutsideClick";

import React, { useRef, useState } from "react";

const DOUBLE_CLICK_TIMEOUT = 1000;

export default function Folder({ data, setCurrentDirectory, onOpen }) {
  const { folderImg, name, items } = data;
  const [clicked, setClicked] = useState(false);
  const firstClick = useRef();
  const timeout = useRef();
  const wrapperRef = useRef(null);
  function onOutsideClick() {
    setClicked(false);
  }

  useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <div
      ref={wrapperRef}
      className={`component-folder col align-center ${clicked && "clicked"}`}
      onClick={() => {
        setClicked(true);
        if (
          firstClick.current &&
          Date.now() - firstClick.current < DOUBLE_CLICK_TIMEOUT
        ) {
          console.log("double click");
          onOpen(data);
          setCurrentDirectory(data);

          firstClick.current = null;
          timeout.current && clearTimeout(timeout.current);
        }

        firstClick.current = Date.now();
        timeout.current = setTimeout(() => {
          firstClick.current = null;
        }, DOUBLE_CLICK_TIMEOUT);
      }}
    >
      <img src={folderImg ? folderImg : defaultFolder} alt="" />
      <div className="title white font-14">{name}</div>
      <div className="mac-blue font-10">
        {items?.length} {items?.length > 1 ? "items" : "item"}
      </div>
    </div>
  );
}
