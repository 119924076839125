import IMAGES from "constants/images";

const GITHUB = [
  {
    name: "card-modal",
    url: "https://github.com/ggomaeng/react-native-card-modal",
    image: IMAGES.github,
  },
  {
    name: "facebook-ui",
    url: "https://github.com/ggomaeng/react-native-facebook-ui",
    image: IMAGES.github,
  },
  {
    name: "better-developer-quotes",
    url: "https://github.com/ggomaeng/better-developer-quotes",
    image: IMAGES.github,
  },
  {
    name: "awesome-js",
    url: "https://github.com/ggomaeng/awesome-js",
    image: IMAGES.github,
  },
  {
    name: "spotify-ui",
    url: "https://github.com/ggomaeng/react-native-spotify-ui",
    image: IMAGES.github,
  },
  {
    name: "magazine-listview",
    url: "https://github.com/ggomaeng/react-native-magazine-listview",
    image: IMAGES.github,
  },
  {
    name: "gmail",
    url: "https://github.com/ggomaeng/react-native-gmail",
    image: IMAGES.github,
  },
  {
    name: "tetris",
    url: "https://github.com/ggomaeng/react-native-tetris",
    image: IMAGES.github,
  },
  {
    name: "lottie-minion",
    url: "https://github.com/ggomaeng/react-native-lottie-minion",
    image: IMAGES.github,
  },
  {
    name: "fan-button",
    url: "https://github.com/ggomaeng/react-native-fan-button",
    image: IMAGES.github,
  },
  {
    name: "product-page",
    url: "https://github.com/ggomaeng/react-native-product-page",
    image: IMAGES.github,
  },
  {
    name: "svg-chicken",
    url: "https://github.com/ggomaeng/react-native-svg-chicken",
    image: IMAGES.github,
  },
  {
    name: "solitaire",
    url: "https://github.com/ggomaeng/react-native-solitaire",
    image: IMAGES.github,
  },
  {
    name: "image-card",
    url: "https://github.com/ggomaeng/react-native-image-card",
    image: IMAGES.github,
  },
];
export default GITHUB;
