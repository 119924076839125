import {
  ACTION_OPEN_WINDOW,
  activeWindowsAtom,
  currentWindowIdAtom,
  dockAtom,
} from "atoms/app/state";
import useOutsideClick from "hooks/useOutsideClick";
import { calculateMaxZIndex } from "pages/Mac/utils/WindowManager";

import React, { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

const DOUBLE_CLICK_TIMEOUT = 1000;

export default function File({ data }) {
  const [activeWindows, setActiveWindows] = useRecoilState(activeWindowsAtom);
  const setCurrentWindowId = useSetRecoilState(currentWindowIdAtom);
  const setDock = useSetRecoilState(dockAtom);
  const { action, name, filename, url, image } = data;
  const timeout = useRef();
  const [clicked, setClicked] = useState(false);
  const firstClick = useRef();

  const wrapperRef = useRef(null);

  function onOutsideClick() {
    setClicked(false);
  }

  useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <div
      ref={wrapperRef}
      className={`component-folder col align-center ${clicked && "clicked"}`}
      onClick={() => {
        setClicked(true);
        if (
          firstClick.current &&
          Date.now() - firstClick.current < DOUBLE_CLICK_TIMEOUT
        ) {
          if (action) {
            const { payload, type } = action;
            if (type === ACTION_OPEN_WINDOW) {
              const newId = global.windowId++;
              let maxZindex = calculateMaxZIndex(activeWindows);
              console.log("opening window");
              setCurrentWindowId(newId);
              setDock((prev) => {
                if (prev.some((w) => w.type === payload.type)) {
                  return prev;
                }
                return [...prev, payload];
              });
              setActiveWindows((prev) => [
                ...prev,
                {
                  ...data,
                  type: payload.type,
                  id: newId,
                  visible: true,
                  zIndex: maxZindex + 1,
                },
              ]);
            }
          }
          if (url) {
            window.open(url, "_blank");
          }
          firstClick.current = null;
          timeout.current && clearTimeout(timeout.current);
        }

        firstClick.current = Date.now();
        timeout.current = setTimeout(() => {
          firstClick.current = null;
        }, DOUBLE_CLICK_TIMEOUT);
      }}
    >
      <img src={image} alt="" />
      <div className="title white font-14">{name}</div>
    </div>
  );
}
