import { TYPE_ICON, TYPE_LIST } from "pages/Mac/Finder";
import FolderView from "pages/Mac/Finder/FolderView";
import ListView from "pages/Mac/Finder/ListView";
import React from "react";

export default function FinderContent(props) {
  const { viewType } = props;
  return (
    <div className="finder-content col grow">
      {viewType === TYPE_ICON && <FolderView {...props} />}
      {viewType === TYPE_LIST && <ListView {...props} />}
    </div>
  );
}
