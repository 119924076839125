import {
  activeWindowsAtom,
  currentWindowIdAtom,
  dockAtom,
  minimizedWindowsAtom,
} from "atoms/app/state";
import { calculateMaxZIndex } from "pages/Mac/utils/WindowManager";
import React from "react";
import Dock from "react-osx-dock";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function DockMenu() {
  const [activeWindows, setActiveWindows] = useRecoilState(activeWindowsAtom);
  const [dock, setDock] = useRecoilState(dockAtom);
  const [minimizedWindows, setMinimizedWindows] =
    useRecoilState(minimizedWindowsAtom);
  const setCurrentWindowId = useSetRecoilState(currentWindowIdAtom);

  return (
    <div className="component-dock col align-center">
      <Dock
        width={dock.length * 60}
        magnification={0.5}
        magnifyDirection="up"
        backgroundClassName="dock-bg"
        // debug
      >
        {dock.map((item, index) => (
          <Dock.Item
            key={index}
            onClick={() => {
              if (item.js) {
                item.js();
                return;
              }
              let maxZindex = calculateMaxZIndex(activeWindows);
              const newId = global.windowId++;

              setCurrentWindowId(newId);
              setActiveWindows((prev) => [
                ...prev,
                {
                  ...item,
                  type: item.type,
                  id: newId,
                  visible: true,
                  zIndex: maxZindex + 1,
                },
              ]);
            }}
          >
            <div className="relative dock-item">
              <img src={item.image} alt="" />
              <div className="tooltip">{item.app}</div>
              {[...activeWindows, ...minimizedWindows].some(
                ({ type }) => type === item.type
              ) && <div className="active-dot" />}
            </div>
          </Dock.Item>
        ))}
      </Dock>
    </div>
  );
}
