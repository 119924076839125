import mint from "assets/icons/mint.svg";
import dixel from "assets/icons/dixel.svg";
import github from "assets/icons/github.png";
import programmingFolder from "assets/icons/folder-programming.png";
import designFolder from "assets/icons/folder-design.png";
import htmlFolder from "assets/icons/folder-html.png";
import githubFolder from "assets/icons/folder-github.png";
import textedit from "assets/icons/textedit.png";
import warren from "assets/icons/warren.svg";
import hunt from "assets/icons/hunt.png";
import nomadtask from "assets/icons/nomadtask.svg";
import gudoks from "assets/icons/gudoks.svg";
import coronatasks from "assets/icons/coronatasks.png";

const IMAGES = {
  mint,
  dixel,
  programmingFolder,
  designFolder,
  htmlFolder,
  githubFolder,
  textedit,
  warren,
  hunt,
  nomadtask,
  gudoks,
  coronatasks,
  github,
};

export default IMAGES;
