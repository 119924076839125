import File from "pages/Mac/File";
import Folder from "pages/Mac/Folder";
import React from "react";

export default function FolderView({
  currentDirectory,
  setCurrentDirectory,
  setUndoStack,
  setRedoStack,
}) {
  return (
    <div className="component-folderview row wrap">
      {currentDirectory?.items.map((item, index) => {
        if (item.items) {
          return (
            <Folder
              key={`folder-${index}-${item.path}`}
              data={item}
              setCurrentDirectory={setCurrentDirectory}
              onOpen={(directory) => {
                setRedoStack([]);
                setUndoStack((prev) => [...prev, directory]);
              }}
            />
          );
        } else {
          return <File key={`file-${index}-${item.name}`} data={item} />;
        }
      })}
    </div>
  );
}
