import React from "react";
import folder from "assets/icons/folder.png";
import home from "assets/icons/home.png";

export default function SidebarItem({ path, name, active, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`component-sidebar-item row align-center top-10 bottom-10 ${
        active && "active"
      }`}
    >
      <img src={path === "/" ? home : folder} alt="" />
      <div className="white font-14 left-10">{name}</div>
    </div>
  );
}
