import FileList from "pages/Mac/Finder/FileList";
import FolderList from "pages/Mac/Finder/FolderList";
import React from "react";

export default function ListView({
  currentDirectory,
  setCurrentDirectory,
  setUndoStack,
  setRedoStack,
}) {
  return (
    <div className="component-listview col grow">
      {currentDirectory?.items.map((item, index) => {
        if (item.items) {
          return (
            <FolderList
              key={`folder-${index}-${item.path}`}
              data={item}
              setCurrentDirectory={setCurrentDirectory}
              index={index}
              onOpen={(directory) => {
                setRedoStack([]);
                setUndoStack((prev) => [...prev, directory]);
              }}
            />
          );
        } else {
          return (
            <FileList
              key={`file-${index}-${item.name}`}
              data={item}
              index={index}
            />
          );
        }
      })}
    </div>
  );
}
