import React from "react";
import bg from "assets/images/bg.jpeg";
import Header from "pages/Mac/Header";
import Dock from "pages/Mac/Dock";
import Desktop from "pages/Mac/Desktop";

export default function Mac() {
  return (
    <div className="page-mac col">
      <div className="background">
        <img className="max-width max-height" src={bg} alt="" />
      </div>

      <div className="mac-content col grow">
        <Header />
        <Desktop />
        <Dock />
      </div>
    </div>
  );
}
