import React, { useCallback, useEffect, useMemo, useState } from "react";
import appleLogo from "assets/icons/apple-logo.svg";
import { useRecoilValue } from "recoil";
import { activeWindowsAtom, currentWindowIdAtom } from "atoms/app/state";
import dayjs from "dayjs";

export default function Header() {
  const currentWindowId = useRecoilValue(currentWindowIdAtom);
  const activeWindows = useRecoilValue(activeWindowsAtom);
  const [time, setTime] = useState("");

  const item = useMemo(() => {
    return activeWindows.find((item) => item.id === currentWindowId);
  }, [currentWindowId]);

  useEffect(() => {
    let interval;
    function tick() {
      setTime(dayjs().format("ddd MMM H:mm A"));
    }
    tick();
    interval = setInterval(tick, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="component-header row align-center justify-between font-14 unselectable">
      <div className="row align-center">
        <img className="width-16 height-16" src={appleLogo} alt="" />
        <div className="white bold left-25">{item?.app}</div>
      </div>
      <div>
        <div className="white">{time}</div>
      </div>
    </div>
  );
}
