import React, { useEffect } from "react";
import Scene from "threejs/Scene";

export default function ThreeApp() {
  useEffect(() => {
    function onTouchMove(e) {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    }
    document.addEventListener("touchmove", onTouchMove, { passive: false });
    return () =>
      document.removeEventListener("touchmove", onTouchMove, {
        passive: false,
      });
  }, []);
  return <Scene />;
}
