import {
  activeWindowsAtom,
  TYPE_WINDOW_CHROME,
  TYPE_WINDOW_FINDER,
  TYPE_WINDOW_TEXTEDIT,
} from "atoms/app/state";
import Chrome from "pages/Mac/Chrome";
import Finder from "pages/Mac/Finder";
import TextEdit from "pages/Mac/TextEdit";
import React from "react";
import { useRecoilState } from "recoil";

export default function Desktop() {
  const [activeWindows, setActiveWindows] = useRecoilState(activeWindowsAtom);
  return (
    <div id="desktop" className="component-desktop col grow">
      {activeWindows.map((w, i) => {
        if (w.type === TYPE_WINDOW_FINDER && w.visible) {
          return <Finder key={`window-${w.id}`} {...w} />;
        } else if (w.type === TYPE_WINDOW_TEXTEDIT && w.visible) {
          return <TextEdit key={`window-${w.id}`} data={w} />;
        } else if (w.type === TYPE_WINDOW_CHROME && w.visible) {
          return <Chrome key={`window-${w.id}`} data={w} />;
        }
      })}
    </div>
  );
}
