import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ReactApp from "ReactApp";
import ThreeApp from "threejs/ThreeApp";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import useDeviceDetect from "hooks/useDeviceDetect";

export default function App() {
  const { isMobile } = useDeviceDetect();

  if (isMobile) {
    return (
      <DeviceOrientation lockOrientation="landscape" className="max-height">
        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <RecoilRoot>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<ThreeApp />} />
                <Route path="/react" element={<ReactApp />} />
              </Routes>
            </BrowserRouter>
          </RecoilRoot>
        </Orientation>
        {/* Will stay in DOM, but is only visible in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div className="white max-height">
            <p>Please rotate your device</p>
          </div>
        </Orientation>
      </DeviceOrientation>
    );
  }

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ThreeApp />} />
          <Route path="/react" element={<ReactApp />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}
