import { atom } from "recoil";
import finderIcon from "assets/icons/finder.png";
import chromeIcon from "assets/icons/chrome.png";
import githubIcon from "assets/icons/github.png";
import imessageIcon from "assets/icons/imessage.png";
import mailIcon from "assets/icons/mail.png";
import terminalIcon from "assets/icons/terminal.png";
import vscodeIcon from "assets/icons/vscode.png";
import texteditIcon from "assets/icons/textedit.png";

export const TYPE_WINDOW_FINDER = "WINDOW_FINDER";
export const TYPE_WINDOW_CHROME = "WINDOW_CHROME";
export const TYPE_WINDOW_GITHUB = "WINDOW_GITHUB";
export const TYPE_WINDOW_IMESSAGE = "WINDOW_IMESSAGE";
export const TYPE_WINDOW_MAIL = "WINDOW_MAIL";
export const TYPE_WINDOW_TERMINAL = "WINDOW_TERMINAL";
export const TYPE_WINDOW_VSCODE = "WINDOW_VSCODE";
export const TYPE_WINDOW_TEXTEDIT = "WINDOW_TEXTEDIT";

export const ACTION_OPEN_WINDOW = "OPEN_WINDOW";

global.windowId = 0;

export const WINDOW_FINDER = {
  type: TYPE_WINDOW_FINDER,
  image: finderIcon,
  app: "Finder",
};

export const WINDOW_CHROME = {
  type: TYPE_WINDOW_CHROME,
  image: chromeIcon,
  app: "Chrome",
};

export const WINDOW_GITHUB = {
  type: TYPE_WINDOW_GITHUB,
  image: githubIcon,
  app: "Github",
  js: () => window.open("https://github.com/ggomaeng", "_blank"),
};
export const WINDOW_IMESSAGE = {
  type: TYPE_WINDOW_IMESSAGE,
  image: imessageIcon,
  app: "iMessage",
};
export const WINDOW_MAIL = {
  type: TYPE_WINDOW_MAIL,
  image: mailIcon,
  app: "Mail",
  js: () => window.open("mailto:sungwoopark95@gmail.com", "_blank"),
};
export const WINDOW_TERMINAL = {
  type: TYPE_WINDOW_TERMINAL,
  image: terminalIcon,
  app: "Terminal",
};

export const WINDOW_VSCODE = {
  type: TYPE_WINDOW_VSCODE,
  image: vscodeIcon,
  app: "Visual Studio Code",
};

export const WINDOW_TEXTEDIT = {
  type: TYPE_WINDOW_TEXTEDIT,
  image: texteditIcon,
  app: "TextEdit",
};

export const ALL_WINDOWS = [
  WINDOW_FINDER,
  WINDOW_CHROME,
  WINDOW_GITHUB,
  WINDOW_IMESSAGE,
  WINDOW_MAIL,
  WINDOW_TERMINAL,
  WINDOW_VSCODE,
];

export const activeWindowsAtom = atom({
  key: "active-windows-atom",
  default: [],
});

export const dockAtom = atom({
  key: "dock-atom",
  default: ALL_WINDOWS,
});

export const minimizedWindowsAtom = atom({
  key: "minimized-windows-atom",
  default: [],
});

export const currentWindowIdAtom = atom({
  key: "current-window-id-atom",
  default: null,
});
