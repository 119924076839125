import { a as three } from "@react-spring/three";
import { Html, useGLTF } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import ReactApp from "ReactApp";
import * as THREE from "three";
import { CameraControls } from "threejs/CameraControls";

const MACBOOK_WIDTH = 334;

export default function Macbook({ open, hinge, ...props }) {
  const group = useRef();
  const screen = useRef();
  const cameraControl = useRef();
  const texture = useLoader(THREE.TextureLoader, "/images/background.jpg");
  const loading = useLoader(THREE.TextureLoader, "/images/loading.png");
  // Load model
  const { nodes, materials } = useGLTF("/models/mac-draco.glb");
  // Take care of cursor state on hover
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [display, setDisplay] = useState(false);
  const [screenHovered, setScreenHovered] = useState(false);
  const windowWidth = 1600;
  const scale = windowWidth / MACBOOK_WIDTH;
  const width = windowWidth;
  const height = (windowWidth * 2) / 3;

  useEffect(
    () => void (document.body.style.cursor = hovered ? "pointer" : "auto"),
    [hovered]
  );

  useFrame((state) => {
    const t = state.clock.getElapsedTime();

    cameraControl.current.setPosition(0, 30, open ? -124 : -142, true);
    if (open && screenHovered) {
      group.current.rotation.x = THREE.MathUtils.lerp(
        group.current.rotation.x,
        0,
        0.1
      );
      group.current.rotation.y = THREE.MathUtils.lerp(
        group.current.rotation.y,
        0,
        0.1
      );
      group.current.rotation.z = THREE.MathUtils.lerp(
        group.current.rotation.z,
        0,
        0.1
      );

      cameraControl.current.fitToBox(screen.current, true, {
        paddingTop: 2,
        paddingBottom: 2,
      });
      cameraControl.current.rotate(0, -Math.PI / 7, true);
    } else if (open) {
      group.current.rotation.x = THREE.MathUtils.lerp(
        group.current.rotation.x,
        Math.cos(t / 2) / 8 + 0.25,
        0.1
      );
      group.current.rotation.y = THREE.MathUtils.lerp(
        group.current.rotation.y,
        Math.sin(t / 4) / 4,
        0.1
      );
      group.current.rotation.z = THREE.MathUtils.lerp(
        group.current.rotation.z,
        Math.sin(t / 4) / 4,
        0.1
      );

      cameraControl.current.fitToBox(group.current, true, {
        paddingBottom: 20,
        paddingLeft: 2,
        paddingRight: 2,
      });
      cameraControl.current.rotate(0, -Math.PI / 20, true);
    } else {
      group.current.rotation.x = THREE.MathUtils.lerp(
        group.current.rotation.x,
        0,
        0.1
      );
      group.current.rotation.y = THREE.MathUtils.lerp(
        group.current.rotation.y,
        0,
        0.1
      );
      group.current.rotation.z = THREE.MathUtils.lerp(
        group.current.rotation.z,
        0,
        0.1
      );
      const padding = 20;
      cameraControl.current.fitToBox(group.current, true, {
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: padding,
        paddingRight: padding,
      });
      cameraControl.current.rotate(0, -Math.PI / 20, true);
    }
  });

  return (
    <>
      <group
        {...props}
        ref={group}
        dispose={null}
        scale={scale}
        onPointerOver={(e) => setHovered(true)}
        onPointerOut={(e) => setHovered(false)}
      >
        <three.group rotation-x={hinge} position={[0, -0.05, 0.41]}>
          <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              material={materials.aluminium}
              geometry={nodes["Cube008"].geometry}
            />
            <mesh
              material={materials["matte.001"]}
              geometry={nodes["Cube008_1"].geometry}
            />

            <group ref={screen}>
              {!clicked ? (
                <mesh
                  geometry={nodes["Cube008_2"].geometry}
                  onClick={(e) => {
                    if (clicked) return;
                    e.stopPropagation();
                    setScreenHovered(true);
                    setClicked(true);
                    setTimeout(() => {
                      setDisplay(true);
                    }, 1800);
                  }}
                  onPointerEnter={() => setScreenHovered(true)}
                  onPointerLeave={() => setScreenHovered(false)}
                >
                  <meshBasicMaterial map={texture} />
                </mesh>
              ) : (
                <>
                  <mesh geometry={nodes["Cube008_2"].geometry}>
                    <meshBasicMaterial map={loading} />
                    {display && (
                      <Html
                        scale={1 / scale}
                        rotation-x={-Math.PI / 2}
                        position={[0, 0.04, 0]}
                        transform
                        occlude
                      >
                        {/* <div
                          style={{
                            position: "relative",
                            width,
                            height,
                            overflow: "hidden",
                          }}
                        >
                          <ReactApp />
                        </div> */}
                        <iframe
                          title="react frame"
                          width={width}
                          height={height}
                          src="/react"
                          style={{
                            border: "none",
                          }}
                        />
                      </Html>
                    )}
                  </mesh>
                </>
              )}
            </group>
          </group>
        </three.group>
        <mesh
          material={materials.keys}
          geometry={nodes.keyboard.geometry}
          position={[1.79, 0, 3.45]}
        />
        <group position={[0, -0.1, 3.39]}>
          <mesh
            material={materials.aluminium}
            geometry={nodes["Cube002"].geometry}
          />
          <mesh
            material={materials.trackpad}
            geometry={nodes["Cube002_1"].geometry}
          />
        </group>
        <mesh
          material={materials.touchbar}
          geometry={nodes.touchbar.geometry}
          position={[0, -0.03, 1.2]}
        />
      </group>
      <CameraControls ref={cameraControl} />
    </>
  );
}
