import FILE_STRUCTURE from "constants/structure";
import SidebarItem from "pages/Mac/Finder/SidebarItem";
import React from "react";

export default function SideBar({
  id,
  onMaximize,
  onClose,
  currentDirectory,
  setCurrentDirectory,
  setRedoStack,
  setUndoStack,
}) {
  return (
    <div className="component-sidebar col">
      <div className="close-buttons row justify-start left-24">
        <div className="round-button close" onClick={onClose} />
        <div className="round-button minimize" />
        <div className="round-button expand" onClick={onMaximize} />
      </div>

      <div className="col">
        <div className="grey font-12 bold top-40 left-24">Favorites</div>
        <div className="sidebar-items">
          <SidebarItem
            onClick={() => {
              setCurrentDirectory(FILE_STRUCTURE);
              setRedoStack([]);
              setUndoStack((prev) => [...prev, FILE_STRUCTURE]);
            }}
            active={currentDirectory.path === "/"}
            {...FILE_STRUCTURE}
          />
          {FILE_STRUCTURE.items.map((directory) => {
            if (!directory.items) return null;
            return (
              <SidebarItem
                key={directory.path}
                onClick={() => {
                  setCurrentDirectory(directory);
                  setRedoStack([]);
                  setUndoStack((prev) => [...prev, directory]);
                }}
                active={currentDirectory.name === directory.name}
                {...directory}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
