import Mac from "pages/Mac";
import React from "react";
import "styles/main.scss";

export default function ReactApp({ onMouseEnter, onMouseLeave }) {
  return (
    <div
      id="react-app"
      className="col"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(e) => e.stopPropagation()}
    >
      <Mac />
    </div>
  );
}
