import defaultFolder from "assets/icons/default-folder.png";
import useOutsideClick from "hooks/useOutsideClick";

import React, { useRef, useState } from "react";

const DOUBLE_CLICK_TIMEOUT = 1000;

export default function FolderList({
  data,
  setCurrentDirectory,
  onOpen,
  index,
}) {
  const { folderImg, name } = data;
  const [clicked, setClicked] = useState(false);
  const firstClick = useRef();
  const timeout = useRef();
  const wrapperRef = useRef(null);
  function onOutsideClick() {
    setClicked(false);
  }

  useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <div
      ref={wrapperRef}
      className={`component-folder-list row align-center ${
        clicked && "clicked"
      } ${index % 2 === 0 ? "even" : "odd"}`}
      onClick={() => {
        setClicked(true);
        if (
          firstClick.current &&
          Date.now() - firstClick.current < DOUBLE_CLICK_TIMEOUT
        ) {
          console.log("double click");
          onOpen(data);
          setCurrentDirectory(data);

          firstClick.current = null;
          timeout.current && clearTimeout(timeout.current);
        }

        firstClick.current = Date.now();
        timeout.current = setTimeout(() => {
          firstClick.current = null;
        }, DOUBLE_CLICK_TIMEOUT);
      }}
    >
      <img src={folderImg ? folderImg : defaultFolder} alt="" />
      <div className="title white font-14 left-10">{name}</div>
    </div>
  );
}
