import { HEADER_SIZE } from "constants/sizes";
import React, { useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeWindowsAtom, currentWindowIdAtom } from "atoms/app/state";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import { calculateMaxZIndex } from "pages/Mac/utils/WindowManager";
import { getRandomIntInclusive } from "pages/Mac/utils/random";

const DEFAULT = {
  width: 800,
  height: 500,
};

export default function Window({
  children,
  dragHandleClassName = "window-header",
  id,
  filename,
  zIndex,
  hideClose = false,
}) {
  const setActiveWindows = useSetRecoilState(activeWindowsAtom);
  const [currentWindowId, setCurrentWindowId] =
    useRecoilState(currentWindowIdAtom);
  const rndRef = useRef();
  const lastData = useRef();
  const [data, setData] = useState({
    width: 800,
    height: 500,
    x: getRandomIntInclusive(50, 100),
    y: getRandomIntInclusive(50, 100),
  });
  const { width, height, x, y } = data;

  const onClose = () => {
    let filtered;
    setActiveWindows((prev) => {
      filtered = prev.filter((item) => item.id !== id);
      return filtered;
    });
    if (filtered.length > 0) {
      const cloned = cloneDeep(filtered);
      const maxZindex = calculateMaxZIndex(cloned);
      const found = find(cloned, (w) => w.zIndex === maxZindex);
      if (found) {
        setCurrentWindowId(found.id);
      }
    }
  };

  const onMaximize = () => {
    console.log("on maximize called");
    if (
      width === window.innerWidth &&
      height === window.innerHeight - HEADER_SIZE
    ) {
      setData(lastData.current);
      return;
    }
    lastData.current = { x, y, width, height };
    setData({
      x: 0,
      y: 0,
      width: window.innerWidth,
      height: window.innerHeight - HEADER_SIZE,
    });
  };

  return (
    <Rnd
      className="component-window"
      ref={rndRef}
      size={{ width, height }}
      position={{ x, y }}
      onDragStop={(e, d) => {
        setData({ width, height, x: d.x, y: d.y });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setData({
          width: ref.style.width,
          height: ref.style.height,
          ...position,
        });
      }}
      minWidth={500}
      minHeight={400}
      dragHandleClassName={dragHandleClassName}
      style={{ zIndex }}
      onMouseDown={() => {
        setCurrentWindowId(id);
        setActiveWindows((prev) => {
          const cloned = cloneDeep(prev);
          const w = find(cloned, (item) => item.id === id);
          if (w) {
            w.zIndex = calculateMaxZIndex(cloned) + 1;
            console.log(cloned);
            return cloned;
          }
          return prev;
        });
      }}
    >
      <div
        className="max-width max-height col window-content"
        style={{
          paddingTop: hideClose ? 0 : 64,
        }}
      >
        {!hideClose && (
          <div className="window-header row align-center">
            <div className="close-buttons row justify-start">
              <div className="round-button close" onClick={onClose} />
              <div className="round-button minimize" />
              <div className="round-button expand" onClick={onMaximize} />
            </div>
            <div className="white left-40">{filename}</div>
          </div>
        )}
        {children(onClose, onMaximize)}
        {currentWindowId !== id && <div className="dark-overlay" />}
      </div>
    </Rnd>
  );
}
