import React from "react";
import cloneDeep from "lodash/cloneDeep";
import iconView from "assets/icons/iconview.png";
import listView from "assets/icons/listview.png";
import { TYPE_ICON, TYPE_LIST } from "pages/Mac/Finder";

export default function FinderHeader({
  currentDirectory,
  setCurrentDirectory,
  undoStack,
  setUndoStack,
  redoStack,
  setRedoStack,
  viewType,
  setViewType,
}) {
  return (
    <div className="component-finder-header row align-center justify-between max-width">
      <div className="row align-center">
        <svg
          className={`width-24 height-24 ${undoStack.length > 1 && "active"}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512"
          onClick={(e) => {
            if (undoStack.length > 1) {
              e.stopPropagation();
              const cloned = cloneDeep(undoStack);
              setRedoStack(redoStack.concat(cloned.pop())); // pop current one
              setCurrentDirectory(cloned[cloned.length - 1]);
              setUndoStack(cloned);
            }
          }}
        >
          <path d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z" />
        </svg>
        <svg
          className={`width-24 height-24 ${redoStack.length > 0 && "active"}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512"
          onClick={(e) => {
            if (redoStack.length > 0) {
              e.stopPropagation();
              const cloned = cloneDeep(redoStack);
              const popped = cloned.pop();
              setUndoStack(undoStack.concat(popped));
              setCurrentDirectory(popped);
              setRedoStack(cloned);
            }
          }}
        >
          <path d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" />
        </svg>
        <div className="left-20">{currentDirectory.name}</div>
      </div>
      <div className="row align-center view-types">
        <img
          onClick={() => setViewType(TYPE_ICON)}
          className={`${viewType === TYPE_ICON && "active"}`}
          src={iconView}
          alt=""
        />
        <img
          onClick={() => setViewType(TYPE_LIST)}
          className={`${viewType === TYPE_LIST && "active"} left-10`}
          src={listView}
          alt=""
        />
      </div>
    </div>
  );
}
