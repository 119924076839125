import FILE_STRUCTURE from "constants/structure";
import FinderContent from "pages/Mac/Finder/FinderContent";
import FinderHeader from "pages/Mac/Finder/FinderHeader";
import SideBar from "pages/Mac/Finder/SideBar";
import Window from "pages/Mac/Window";
import React, { useState } from "react";

export const TYPE_ICON = "TYPE_ICON";
export const TYPE_LIST = "TYPE_LIST";

export default function Finder({ directory = FILE_STRUCTURE, id, zIndex }) {
  const [currentDirectory, setCurrentDirectory] = useState(directory);
  const [viewType, setViewType] = useState(TYPE_ICON);
  const [undoStack, setUndoStack] = useState([currentDirectory]);
  const [redoStack, setRedoStack] = useState([]);

  return (
    <Window
      dragHandleClassName={"component-finder-header"}
      id={id}
      zIndex={zIndex}
      hideClose
    >
      {(onClose, onMaximize) => {
        return (
          <div className="component-finder row dark max-width max-height">
            <SideBar
              id={id}
              currentDirectory={currentDirectory}
              setCurrentDirectory={setCurrentDirectory}
              setUndoStack={setUndoStack}
              setRedoStack={setRedoStack}
              onMaximize={onMaximize}
              onClose={onClose}
            />

            <div className="col grow">
              <FinderHeader
                currentDirectory={currentDirectory}
                setCurrentDirectory={setCurrentDirectory}
                undoStack={undoStack}
                setUndoStack={setUndoStack}
                redoStack={redoStack}
                setRedoStack={setRedoStack}
                viewType={viewType}
                setViewType={setViewType}
              />
              <FinderContent
                currentDirectory={currentDirectory}
                setCurrentDirectory={setCurrentDirectory}
                undoStack={undoStack}
                setUndoStack={setUndoStack}
                redoStack={redoStack}
                setRedoStack={setRedoStack}
                viewType={viewType}
                setViewType={setViewType}
              />
            </div>
          </div>
        );
      }}
    </Window>
  );
}
